html, body {
  // height: 100%;
  margin: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-link {
  color: #61dafb;
}


.container {
  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: mandatory; /* for older browsers */
  scroll-snap-points-y: repeat(100vh); /* for older browsers */
  scroll-snap-type: y mandatory;
  section{
    height: 100vh;
    position: relative;
  }
  .spacer{
    width: 100%;
    height: 30vh;
  }
  .title{
    position: sticky;
    transition: all 0.75s;
    color: white;
    background-color: transparent;
    top: 0px;
    z-index: 100;
    font-family: 'Audiowide', cursive;
    font-family: 'Julius Sans One', sans-serif;
    font-family: 'Audiowide', cursive;
    height: 60px;
    line-height: 60px;
  }
}

.child {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  &.main{
    background-color: whitesmoke;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .headline-banner{
      width: 100vw;
      height: 100vh;
      background-size: 100% 100%;
      background-position: center;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: relative;
      color: white;
      background-image: url(../src/assets/images/konjr_splash_title_lowres.png);
      background-image: url(../src/assets/images/konjr_splash_title.png);
      transform: scaleX(1);
    }
  }
  
  &.white{
    background-image: url(../src/assets/images/screen2_graphic.jpg);
    background-size: 100% 100%;
    position: relative;
    .mask{
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(black, #003366);
      opacity: 0;
      z-index: 10;
    }
    .subtext-container{
      position: absolute;
      bottom: 142px;
      font-size: 17px;;
      width: 80%;
      text-align: left;
      margin-left: 80px;
      color: white;
      z-index: 80;
      line-height: 29px;
      font-family: 'Julius Sans One', sans-serif;
      font-family: 'Montserrat', sans-serif;
    }
    .circles-super-container{
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 100%;
      .circle-individual-container{
        position: relative;
        margin-top: -85px;
        height: 300px;
          width: 300px;
          z-index: 49;
        .text-overlay{
          position: absolute;
          color: white;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background-image: none;
          z-index: 51;
          height: 100%;
          width: 100%;
          flex-direction: column;
          font-family: 'Julius Sans One', sans-serif;
          .icon{
            height: 80px;
            width: 80px;
            margin-bottom: 25px;
            margin-top: 40px;
            background-size: 100% 100%;
            &.sensory{
              background-image: url('../src/assets/images/icons/sensory.png');
            }
            &.context{
              background-image: url('../src/assets/images/icons/context.png');
            }
            &.integration{
              background-image: url('../src/assets/images/icons/integration.png');
            }
          }
          .large-text{
            line-height: 147px;
            font-size: 45px; 
            margin-top: -50px;  
          }
          .small-text{
            margin-top: -20px;
            font-size: 22px;
            width: 90%;
            line-height: 34px;
            width: 82%;
          }
        }
        .circle{
          z-index: 50;
          height: 300px;
          width: 300px;
          border-radius: 300px;
          background-color: darkcyan;
          -webkit-mask-image: linear-gradient(to top, transparent 1%, black);
          color: white;
          .large-text{
            line-height: 147px;
            font-size: 60px;
            margin-top: 40px;    
          }
          .small-text{
            font-size: 30px;
          }
    
        }
      }
    }
  }
  &.lavender{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;
    .top-layer{
      position: absolute;
      height: 100vh;
      width: 100vw;
      z-index: 101;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .card{
        opacity: 0;
        height: 35vh;
        width: 30vw;
        border: 1px solid slategray;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        color: white;
        text-align: left;
        line-height: 150%;
        height: 15vh;
        width: 45vw;
        padding: 20px;
        &.text{
          .text-area{
            width: 78%;
          }
        }
        &.top{
          z-index: 100;
          border: 1px solid white;
          margin-bottom: 110px;
          margin-left: -51px;
          background-color: rgba(245, 255, 250, 0.618);
          background-image: linear-gradient(to right, #082c6c,  black);
        }
        &.bottom{
          z-index: 100;
          border: 1px solid white;
          margin-top: 110px;
          margin-right: -51px;
          background-image: linear-gradient(to right, black, #082c6c);
          
        }
        cursor: pointer;
      }
    }
    .mask{
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(black, #003366);
      opacity: 0;
      z-index: 10;
    }
    .row{
      z-index: 20;
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-evenly;
      .image{
        height:  100%;
        &.touch{
          background-image: url(../src/assets/images/medium_res/touch.jpeg);
          background-size: 100% 100%;
          width: 65vw;
          height: 50vh;
          position: absolute;
          left: -50px;
        }
        &.search{
          background-image: url(../src/assets/images/medium_res/search.jpeg);
          background-size: 100% 100%;
          width: 65vw;
          height: 50vh;
          position: absolute;
          right: -50px;
          // transform: translateX(100px);
        }
      }
      
    }
  }
  &.peach{
    // background-color: peachpuff;
    background-image: linear-gradient(to bottom right, navy, black);;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-bottom: 5px solid black;
}

.triangle-container{
  margin-bottom: -0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: 'Julius Sans One', sans-serif;
  .equilateralTriangle {
    width: 0;
    height: 0;
    border-right: 100px solid transparent;
    border-left: 100px solid transparent;
    // border-bottom: 173.2px solid black;
    border-bottom: 173.2px solid transparent;

    display: flex;
    justify-content: center;
  }
  .subtext{
    color: white;
    // margin-left: 200px;
    position: absolute;
    top: 0px;
    width: 200%;
    // right: 0px;
  }
}



.parent {
  overflow: scroll;
  height: 100vh;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
}

section {
  height: 100vh;
  scroll-snap-align: start;
  position: relative;
}

.navbar{
  position: fixed;
  width: 100%;
  top: 0;
  height: 60px;
  z-index: 105;
  // padding-left: 80px;
  background: 'linear-gradient(90deg, #57a6a8, black)';
  font-family: 'Julius Sans One', sans-serif;
  font-family: 'Audiowide', cursive;
  height: 60px;
  line-height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  // -webkit-mask-image: linear-gradient(to top, transparent 1%, black);
  height: 80px;
  .navbar-mask{
    -webkit-mask-image: linear-gradient(to top, transparent 1%, black);
    // background-color: #003366;
    background-color: black;
    height: 80px;
    z-index: 30;
    position: absolute;
    width: 100%;
    height: 100%;
  }  
  // div{
  //   margin-right: 40px;
  // }
  .konjr-logo{
    position: absolute;
    left: 80px;
    z-index: 50;
    color: white;
  }
  .navbar-buttons-container{
    z-index: 50;
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-right: 20px;
    position: relative;
    .about-button, .blog-button, .services-button{
      margin-right: 40px;
      color: white;
      font-family: 'Julius Sans One', sans-serif;
      font-size: 18px;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      border-bottom: 1px solid transparent;
      &:hover{
        border-bottom: 1px solid white;
      }
    }
    .coming-soon{
      position: absolute;
      color: white;
      font-size: 14px;
      letter-spacing: 0.4em;
      bottom: -55px;
      text-align: center;
      padding-right: 20px;
      width: 100%;
      pointer-events: none;
      opacity: 0;
      // transition: opacity 1s ease-in;
      &.fade-in{
        animation: fadeIn 1s forwards;
      }
      &.fade-out{
        animation: fadeOut 1s forwards;
      }
    }
  }
}
.picture-container{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  padding-left: 300px;
  .konjr-title{
    height: 150px;
    width: 300px;
    z-index: 105;
    background-position: center;
    background-image: url(../src/assets/images/konjr.png);
    background-size: 100% 100%;
    margin-top: -180px;

  }
}
.down-arrow{
  background-image: url(../src/assets/images/arrows/wide_arrow_filled.png);
  height: 25px;
  width: 25px;
  z-index: 150;
  background-position: center;
  background-size: 100% 100%;
  position: absolute;
  margin-top: 130px;
}

.bounce {
  animation: bounce 2s ease-in-out alternate infinite;
}

@keyframes bounce {
  0% {
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.footer{
  width: 100vw;
  position: absolute;
  bottom: 5px;
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
  z-index: 101;
  color: white;
  font-size: 12px;
  height: 25px;
  .container{
    width: 35vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 10px;
    .privacy-policy{
      line-height: 25px;
      margin-right: 23px;
      cursor: pointer;
      &:hover{
        color: cyan;
      }
    }
    .copyright{
      line-height: 25px;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(50px);
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(50px);
  }
}
@keyframes slideInLeft {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(-50px);
  }
}

@keyframes slideInRight2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(50px);
  }
}
@keyframes slideInLeft2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(-50px);
  }
}

@keyframes fadeOutBackground {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}


@media(max-width: 965px){
  .footer{
    font-size: 10px;
  }
  .circle-individual-container{
    height: 250px !important;;
    width: 250px !important;;
    .circle{
      height: 250px !important;;
      width: 250px !important;;
    }
  }
}

@media(max-width: 832px){
  .footer{
    font-size: 8px;
  }
}

@media(max-width: 800px){
  .image.touch, .image.search{
    width: 60vw !important;
  }
  .circle-individual-container{
    height: 200px !important;;
    width: 200px !important;;
    .circle{
      height: 200px !important;;
      width: 200px !important;;
    }
  }
  .icon{
    height: 50px !important;
    width: 50px !important;
  }
  .small-text{
    font-size: 16px !important;
  }
}